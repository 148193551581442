import axios from "axios";
import Setting from "setting";
import SettingModel from "common/models/SettingModel";
import BaseApi from "common/api/BaseApi";
import {
	LoaderFunction,
	RouteObject,
	createHashRouter,
} from "react-router-dom";
import {
	AddressView,
	Auth,
	Comment,
	CouponList,
	Customer,
	CustomerAddressForm,
	CustomerAddressList,
	CustomerConfirmPassword,
	CustomerEditEmail,
	CustomerEditPhone,
	CustomerEditUsername,
	CustomerMenu,
	CustomerProfile,
	CustomerResetForm,
	CustomerResetPassword,
	CustomerReward,
	CustomerVerify,
	CustomerView,
	FeedbackSuccess,
	ForgotPassword,
	Login,
	Logout,
	MainTest,
	MyReward,
	NLContact,
	NLFeedback,
	NLLetter,
	NewLetter,
	Register,
	ResetPassword,
	UpdateCustomer,
	VerifyOTP,
} from "./ImportFile";
import PageNotFound from "templates/PageNotFound";
import RewardTerm from "features/website/pages/RewardTerm";
import CurrentRated from "features/comment/components/totalReviewRated/CurrentRated";
/////////////////////////
export type getRouterProps = {
	feature: string;
};
export type Features =
	| "comment"
	| "login"
	| "newsletter"
	| "feedback"
	| "contact"
	| "reward";
////////////////////////
export const getApiSetting = async ({
	key,
}: {
	key: string;
}): Promise<SettingModel> => {
	let item = new SettingModel(SettingModel.getDefaultData());
	try {
		const res = await axios.get(
			Setting.REACT_APP_BASE_URL + "/settings/public/" + key
		);
		if (res.hasOwnProperty("data")) {
			item = new SettingModel(res.data);
		}
	} catch (error) {
		item.withError(BaseApi.handleError(error));
		// return new BaseApi(error);
	}
	return item;
};

export const getRouter = ({ feature }: getRouterProps) => {
	let loader: LoaderFunction | undefined;
	let Com = null;
	let children: RouteObject[] | undefined = undefined;

	const CustomerGroup: string[] = ["login", "reward"];

	switch (feature) {
		case "comment":
			Com = <Comment></Comment>;
			break;
		case "login":
			Com = <Auth></Auth>;
			children = [
				{
					index: true,
					element: <Login></Login>,
				},
				{
					path: "register",
					element: <Register></Register>,
				},
				{
					path: "verifyotp",
					element: <VerifyOTP></VerifyOTP>,
				},
				{
					path: "forgotpassword",
					element: <ForgotPassword></ForgotPassword>,
				},
				{
					path: "resetpassword",
					element: <ResetPassword></ResetPassword>,
					children: [],
				},
				{
					path: "updatecustomer",
					element: <UpdateCustomer />,
					children: [
						{ path: "confirmpassword", element: <CustomerConfirmPassword /> },
						{ path: "editemail", element: <CustomerEditEmail /> },
						{ path: "verify", element: <CustomerVerify /> },
						{ path: "editphone", element: <CustomerEditPhone /> },
						{ path: "editpassword", element: <CustomerResetPassword /> },
						{ path: "editusername", element: <CustomerEditUsername /> },
					],
				},
			];
			break;
		// case "newsletter":
		//   Com = <NewLetter></NewLetter>;
		//   children = [
		//     {
		//       index: true,
		//       element: <NLLetter />,
		//     },
		//   ];
		//   break;
		case "feedback":
			Com = <NewLetter></NewLetter>;
			children = [
				{
					index: true,
					path: ":receive_from?",
					element: <NLFeedback />,
				},
				{
					path: "success",
					element: <FeedbackSuccess />,
				},
			];
			break;
		case "contact":
			Com = <NewLetter></NewLetter>;
			children = [
				{
					index: true,
					element: <NLContact />,
				},
			];
			break;
		case "reward":
			Com = <CustomerReward></CustomerReward>;
			break;
		case "term":
			Com = <RewardTerm></RewardTerm>;
			break;
	
		default:
			Com = <></>;
			break;
	}

	const router = createHashRouter(
		CustomerGroup.includes(feature)
			? [
					{
						path: "/",
						element: Com,
						children: children,
						loader: loader,
						// hasErrorBoundary: false,
						errorElement: <PageNotFound type="page"></PageNotFound>,
					},
					{
						path: "me",
						element: <Customer></Customer>,
						children: [
							// {
							//   index: true,
							//   element: <CustomerIndex></CustomerIndex>,
							// },
							{
								path: "myprofile",
								element: <CustomerView></CustomerView>,
								children: [
									{
										index: true,
										element: <CustomerProfile />,
									},
									{
										path: "resetpassword",
										element: <CustomerResetForm />,
									},
								],
							},
							{
								path: "mycoupon",
								element: <CouponList></CouponList>,
							},
							{
								path: "myaddress",
								element: <AddressView></AddressView>,
								children: [
									{
										index: true,
										element: <CustomerAddressList />,
									},
									{
										path: "form",
										element: <CustomerAddressForm />,
									},
								],
							},
							{
								path: "myreward",
								element: <MyReward></MyReward>,
							},
						],
					},
					{
						path: "menu",
						element: <CustomerMenu></CustomerMenu>,
					},
					{
						path: "logout",
						element: <Logout></Logout>,
					},
					{
						path: "login",
						element: <Login></Login>,
					},
					{
						path: "*",
						element: <MainTest></MainTest>,
					},
			  ]
			: [
					{
						path: "/",
						element: Com,
						children: children,
						loader: loader,
						errorElement: <PageNotFound type="page"></PageNotFound>,
					},
					{
						path: "*",
						element: <MainTest></MainTest>,
					},
			  ]
	);
	return router;
};
