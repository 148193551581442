import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { CommentJson } from "./types/comment.type";
import { DataType } from "./components/list/CommentList";
import Helper from "common/utils/helper";
// import { fetchCount } from "./counterAPI";

export interface CommentState {
	url: string;
	product_id: string;
	type: string;
	listComment: DataType[];
	listReview: DataType[];
	listQa: DataType[];
	listReviewGallley: DataType[];
	model: CommentJson | null;
	detailProduct: {
		title: string;
		handle: string;
		linkImageProduct: string;
		vendor: string;
		gender: string;
	};
	countRate: number;
	rate: number;
}
interface MyObject {
	[key: string]: any;
}

const initialState: CommentState = {
	url: "https://cross.namefragrance.vn/imgs/icons",
	product_id: "1022914732",
	type: "",
	listComment: [],
	listReview: [],
	listQa: [],
	listReviewGallley: [],
	model: null,
	detailProduct: {
		handle: "",
		title: "",
		vendor: "",
		gender: "",
		linkImageProduct: "",
	},
	countRate: 0,
	rate: 5,
};
// 1019509882
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number, thunkAPI) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const CommentSlice = createSlice({
	name: "comment",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setProductId: (state, action) => {
			state.product_id = action.payload;
		},
		setType: (state, action) => {
			state.type = action.payload;
		},

		// set list
		setListComment: (
			state,
			{
				payload: { data, type },
			}: { payload: { data: DataType[]; type: "full" | "one" } }
		) => {
			if (type === "full") {
				state.listComment = data;
			} else {
				const newArray = [...data].concat([...state.listComment]);
				state.listComment = newArray;
			}
		},
		setListReview: (
			state,
			{
				payload: { data, type },
			}: { payload: { data: DataType[]; type: "full" | "one" } }
		) => {
			if (type === "full") {
				state.listReview = data;
			} else {
				const newArray = [...data].concat([...state.listReview]);
				state.listReview = newArray;
			}
		},
		setListQa: (
			state,
			{
				payload: { data, type },
			}: { payload: { data: DataType[]; type: "full" | "one" } }
		) => {
			if (type === "full") {
				state.listQa = data;
			} else {
				const newArray = [...data].concat([...state.listQa]);
				state.listQa = newArray;
			}
		},
		setlistReviewGalley: (
			state,
			{ payload: { data } }: { payload: { data: DataType[] } }
		) => {
			state.listReviewGallley = data;
		},

		// update Count like Review
		setCountLikeReview: (
			state,
			{
				payload: { id, newCount },
			}: { payload: { id: number; newCount: number } }
		) => {
			const idItem = id;
			for (let index = 0; index < state.listReview.length; index++) {
				const element: CommentJson = state.listReview[index];
				if (element.id === idItem) {
					state.listReview[index].like = newCount;
					break;
				}
			}
		},
		//
		setModel: (state, action) => {
			state.model = action.payload;
		},
		// set detail product + Url Asset liquid
		setDetailProduct: (
			state,
			{
				type,
				payload,
			}: {
				type: any;
				payload: {
					linkImageProduct: string;
					title: string;
					handle: string;
					vendor: string;
					gender: string;
				};
			}
		) => {
			state.detailProduct.linkImageProduct = payload?.linkImageProduct;
			state.detailProduct.title = payload?.title;
			state.detailProduct.handle = payload?.handle;
			state.detailProduct.vendor = payload?.vendor;
			state.detailProduct.gender = payload?.gender;
		},
		setUrl: (state, action) => {
			state.url = action.payload;
		},
		setCountRate: (state, action) => {
			state.countRate = action.payload;
		},
		setRate: (state, action) => {
			state.rate = action.payload;
		},
		increate: (state, action) => {
			state.product_id += 1;
		},
		increment: (state) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state.product_id += 1;
		},
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	// extraReducers: (builder) => {
	//   builder
	//     .addCase(incrementAsync.pending, (state) => {
	//       state.status = "loading";
	//     })
	//     .addCase(incrementAsync.fulfilled, (state, action) => {
	//       state.status = "idle";
	//       state.value += action.payload;
	//     })
	//     .addCase(incrementAsync.rejected, (state) => {
	//       state.status = "failed";
	//     });
	// },
});
export const selectListComment = (state: RootState) =>
	state.comment.listComment;
export const selectListReview = (state: RootState) => state.comment.listReview;
export const selectListQa = (state: RootState) => state.comment.listQa;
export const selectUrlAsset = (state: RootState) => state.comment.url;
export const selectDetailProduct = (state: RootState) =>
	state.comment.detailProduct;
export const selectCountRate = (state: RootState) => state.comment.countRate;
export const selectRate = (state: RootState) => state.comment.rate;
export const {
	setProductId,
	increate,
	increment,
	setListComment,
	setListReview,
	setListQa,
	setlistReviewGalley,
	setModel,
	setType,
	setDetailProduct,
	setUrl,
	setCountLikeReview,
	setCountRate,
	setRate,
} = CommentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.comment.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default CommentSlice.reducer;
